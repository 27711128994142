<template>
    <div style="padding: 20px;">
        <van-form @submit="onSubmit">
            <van-field left-icon="manager-o" v-model="username" label="用户名"
                :rules="[{ required: true, message: '请输入用户名' }]" />

            <van-field left-icon="manager-o" v-model="password" type="password" label="密码"
                :rules="[{ required: true, message: '请输入密码' }]" />

            <div style="margin: 16px;">
                <van-button round block type="info" size="small" native-type="submit">
                    提交
                </van-button>
            </div>
        </van-form>

        <div style="margin: 16px;">
            <van-button icon="wechat" round block type="primary" size="small" @click='wxAciton'>
                微信登录
            </van-button>
        </div>

    </div>
</template>

<script>

import { Form, Field, Button, Toast, Dialog } from "vant";

var urlencode = require('urlencode');

export default {
    components: {
        vanForm: Form,
        vanField: Field,
        vanButton: Button,
    },
    data() {
        return {
            username: null,
            password: null,

            app_id: 'wxca7bff58cc92ada9'
        }
    },
    mounted() {

        // 判断是否微信浏览器
        if (window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == 'micromessenger') {

            // 判断为授权后回调
            if (this.$route.query.code != null) {
                let app_id = this.app_id;
                let code = this.$route.query.code;
                let person_type = 'STAFF';

                this.http.post(this.ports.user.wx, {
                    code: code,
                    app_id: app_id,
                    person_type: person_type,
                }, res => {

                    console.log(res);

                    this.http.post(this.ports.user.login, {
                        app_id: this.app_id,
                        open_id: res.open_id,
                        type: "STAFF",
                        platform: "WAP",
                    }, res => {

                        // console.log('登录接口返回：', res);
                        Toast.success('登录成功');

                        this.$store.dispatch('LOGIN', {
                            access_token: res.access_token,
                            refresh_token: res.refresh_token,
                        })

                        this.$router.replace(
                            {
                                path: '/domain',
                                query: { redirect: this.$route.query.redirect }
                            }
                        )

                    }, err => {
                        console.log(err);
                        Dialog({ message: '登录失败，当前openid为：' + res.open_id });
                        // Toast.fail();
                    })


                }, err => {
                    console.log(err);
                });

            }
        }


    },
    methods: {
        onSubmit() {
            this.http.post(this.ports.user.login, {
                account: this.username,
                password: this.password,
                type: "STAFF",
                platform: "WAP",
                developer_mode: false,
            }, res => {

                console.log('登录接口返回：', res);
                Toast.success('登录成功');

                this.$store.dispatch('LOGIN', {
                    access_token: res.access_token,
                    refresh_token: res.refresh_token,
                })

                this.$router.replace(
                    {
                        path: '/domain',
                        query: { redirect: this.$route.query.redirect }
                    }
                )

            }, err => {
                console.log(err);
                Toast.fail('用户名或密码错误');
            })
        },
        wxAciton() {
            // 第一步：用户同意授权，获取code
            //WXAuthUtil.APPID 微信公众号的appenid 这个是微信公众号后台获取的
            var url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + this.app_id
                + "&redirect_uri=" + urlencode("https://scan.chinaleather.com/#/login") //这个是我们回调的地址 在这里进行出来获取到用户的心
                + "&response_type=code"
                + "&scope=snsapi_userinfo"
                + "&state=123#wechat_redirect";

            window.location.href = url;
        }
    }
};
</script>